import { Entities } from '@scrollmax/core-nextgen';
import { TranslateInstance } from './translate.instance.entity';

export class TranslateNode extends Entities.CoreEntity {

    public static override kind: string = 'ScrollMax\\Core\\Translate\\Entity\\TranslateNode';

    tag: string;

    builtin: boolean = true;

    uuid: string;

    description: string;

    instances: TranslateInstance[];

    retranslate: boolean;

    used: boolean;

    getTag(): string {
        return this.tag;
    }

    setTag(tag: string): TranslateNode {
        this.tag = tag;
        return this;
    }

    setBuiltIn(builtin: boolean): TranslateNode {
        this.builtin = builtin;
        return this;
    }

    isBuiltIn(): boolean {
        return this.builtin;
    }

    getDescription(): string {
        return this.description;
    }

    setDescription(description: string): TranslateNode {
        this.description = description;
        return this;
    }

    getUuid(): string {
        return this.uuid;
    }

    getInstances(): TranslateInstance[] {
        return this.instances;
    }

    setInstances(instances: TranslateInstance[]): TranslateNode {
        this.instances = instances;
        return this;
    }

    isReTranslate(): boolean {
        return this.retranslate;
    }

    isUsed(): boolean {
        return this.used;
    }
    
}