import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateFn, CanActivateChildFn, CanActivate } from '@angular/router';
import { filter, pairwise } from 'rxjs/operators';
import { AuthService } from '../service/auth.service';
import { AppLog } from '../util/log.util';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  constructor(
    private authService: AuthService, 
    private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((resolve) => {
      
      if (this.authService.getProfile()) {

        AppLog.log('User already logged in', this.authService.getProfile());  

        // van user profile, tehát úgy vesszük, hogy az user be van lépve
        // egyben azt is nézzük, hogy az AppGuard-on átmegy -e az éppen aktuális route
        return resolve(true);
      } 
  
      if (this.authService.isFirstLoading) {
  
        this.authService.isFirstLoading = false;

        setTimeout(() => {

          if (this.authService.getProfile() !== undefined) {
            // van user profile, tehát úgy vesszük, hogy az user be van lépve
            resolve(true);
          } else {

            AppLog.message('Logging out (User profile fetch failed till the prefetch time)');  

            // ha idáig eljutunk, akkor nem talátunk user profile-t, tehát elirányítjuk a felhasználót a login oldalra
            this.authService.onLogout();
            this.router.navigate(['/auth/login']);

            return resolve(false);
          }
        }, 300);      
  
      } else {

        AppLog.message('Logging out (User profile not present)');  
        
        // ha idáig eljutunk, akkor nem talátunk user profile-t, tehát elirányítjuk a felhasználót a login oldalra
        this.authService.onLogout();
        this.router.navigate(['/auth/login']);

        resolve(false);
      }
      
    });

  }

}
