import { Entities } from '@scrollmax/core-nextgen';

export class TranslateProject extends Entities.CoreEntity {

    public static override kind: string = 'ScrollMax\\Core\\Translate\\Entity\\TranslateProject';

    name: string;

    description: string;

    copy_allowed: boolean;

    getName(): string {
        return this.name;
    }

    getDescription(): string {
        return this.description;
    }

    isCopyAllowed(): boolean {
        return this.copy_allowed;
    }

}